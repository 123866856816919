import React, { useState } from "react";
import { AnimatePresence, motion, useAnimation } from "framer-motion";
import {
  IconArrowNarrowRight,
  IconBrandGithub,
  IconFileText,
  IconBrandLinkedin,
} from "@tabler/icons-react";
import AnimatedSVG from "./AnimatedSVG";

const BackgroundCircles = () => (
  <svg
    className="absolute inset-0 w-full h-full"
    xmlns="http://www.w3.org/2000/svg"
  >
    <motion.circle
      cx="10%"
      cy="10%"
      r="5"
      fill="#e0e0e0"
      animate={{ scale: [1, 1.5, 1], opacity: [0.5, 1, 0.5] }}
      transition={{ duration: 4, repeat: Infinity }}
    />
    <motion.circle
      cx="90%"
      cy="90%"
      r="7"
      fill="#d0d0d0"
      animate={{ scale: [1, 1.2, 1], opacity: [0.7, 1, 0.7] }}
      transition={{ duration: 3, repeat: Infinity }}
    />
    <motion.circle
      cx="80%"
      cy="20%"
      r="4"
      fill="#e5e5e5"
      animate={{ scale: [1, 1.3, 1], opacity: [0.6, 1, 0.6] }}
      transition={{ duration: 5, repeat: Infinity }}
    />
  </svg>
);

function Landing({ onProjectsClick }) {
  const [isHoveringCircle, setIsHoveringCircle] = useState(false);
  const [isHoveringUSC, setIsHoveringUSC] = useState(false);
  const circleAnimation = useAnimation();

  const handleCircleHover = (isHovering) => {
    setIsHoveringCircle(isHovering);
    circleAnimation.start({
      scale: isHovering ? 1.05 : 1,
      transition: { duration: 0.3 },
    });
  };

  return (
    <div className="relative w-full min-h-screen flex items-center justify-center py-12 overflow-hidden">
      <BackgroundCircles />

      <div className="relative z-10 flex flex-col lg:flex-row items-center justify-between w-full mx-auto">
        <motion.div
          className="w-full px-4 md:px-16  lg:w-1/2 mb-12 lg:mb-0"
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
        >
          <motion.h1
            className="text-5xl md:text-5xl lg:text-7xl font-extrabold mt-12 md:mt-0 mb-6 text-gray-900 text-start lg:text-left"
            whileHover={{ scale: 1.05 }}
          >
            creating tech
            <br />
            with purpose.
          </motion.h1>
          <motion.p
            className="text-lg px-3 md:px-0 md:text-xl mb-8 max-w-md mx-auto lg:mx-0 text-gray-700 relative text-start lg:text-left"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.5, duration: 0.5 }}
          >
            Hi, I'm{" "}
            <motion.span className="font-medium" whileHover={{ scale: 1.1 }}>
              Cameron Saidock
            </motion.span>
            . I'm a senior at the{" "}
            <motion.span
              className="font-medium relative inline-block"
              onHoverStart={() => setIsHoveringUSC(true)}
              onHoverEnd={() => setIsHoveringUSC(false)}
            >
              University of Southern California
              <AnimatePresence>
                {isHoveringUSC && (
                  <motion.span
                    className="absolute left-0 -bottom-1 w-full h-0.5 bg-gray-900"
                    initial={{ scaleX: 0 }}
                    animate={{ scaleX: 1 }}
                    exit={{ scaleX: 0 }}
                    transition={{ duration: 0.2 }}
                  />
                )}
              </AnimatePresence>
              <AnimatePresence>
                {isHoveringUSC && (
                  <motion.span
                    className="absolute left-full ml-2 top-0 transform -translate-y-1/2 bg-gray-900 text-white px-2 py-1 rounded-md text-sm whitespace-nowrap"
                    initial={{ opacity: 0, x: -10 }}
                    animate={{ opacity: 1, x: 0 }}
                    exit={{ opacity: 0, x: -10 }}
                    transition={{ duration: 0.2 }}
                  >
                    Fight On! ✌️
                  </motion.span>
                )}
              </AnimatePresence>
            </motion.span>{" "}
            merging{" "}
            <motion.span className="font-medium" whileHover={{ scale: 1.1 }}>
              Computer Science
            </motion.span>{" "}
            &{" "}
            <motion.span className="font-medium" whileHover={{ scale: 1.1 }}>
              Business
            </motion.span>{" "}
            to build innovative solutions that make a difference.
          </motion.p>
          <div className="flex justify-start">
            <motion.button
              className="group flex items-center space-x-2 bg-black text-white px-6 py-3 rounded-full text-lg font-semibold shadow-lg hover:shadow-xl transition-all duration-300"
              onClick={onProjectsClick}
              whileHover={{ scale: 1.05, backgroundColor: "#333" }}
              whileTap={{ scale: 0.95 }}
            >
              <span>Explore My Work</span>
              <motion.div
                animate={{ x: [0, 5, 0] }}
                transition={{ repeat: Infinity, duration: 1.5 }}
              >
                <IconArrowNarrowRight size={24} />
              </motion.div>
            </motion.button>
          </div>
          <motion.div
            className="mt-12 space-y-4 lg:space-y-0 lg:space-x-6 flex flex-col lg:flex-row items-start pl-4 md:pl-0 justify-start"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 1, duration: 0.5 }}
          >
            {[
              {
                name: "Github",
                icon: IconBrandGithub,
                link: "https://github.com/CamSaidock",
              },
              {
                name: "Resume",
                icon: IconFileText,
                link: "https://cameronsaidockresume.tiiny.site/",
              },
              {
                name: "LinkedIn",
                icon: IconBrandLinkedin,
                link: "https://www.linkedin.com/in/CameronSaidock",
              },
            ].map(({ name, icon: Icon, link }) => (
              <motion.a
                key={name}
                href={link}
                target="_blank"
                rel="noopener noreferrer"
                className="flex items-center text-gray-700 hover:text-black transition-colors duration-300"
                whileHover={{ scale: 1.1, y: -5 }}
                whileTap={{ scale: 0.9 }}
              >
                <Icon size={24} className="mr-2" />
                <span className="font-medium">{name}</span>
              </motion.a>
            ))}
          </motion.div>
        </motion.div>

        <motion.div
          className="w-full lg:w-1/2 flex justify-center lg:justify-end items-start md:items-center"
          initial={{ opacity: 0, x: 50 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.8, delay: 0.2 }}
        >
          <AnimatedSVG />
        </motion.div>
      </div>

      <motion.div
        className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-[80vw] h-[80vw] max-w-[800px] max-h-[800px] rounded-full border border-gray-200 shadow-sm hidden lg:block"
        style={{
          mixBlendMode: isHoveringCircle ? "difference" : "normal",
          backgroundColor: isHoveringCircle
            ? "white"
            : "rgba(255, 255, 255, 0.5)",
        }}
        initial={{ scale: 0 }}
        animate={circleAnimation}
        onHoverStart={() => handleCircleHover(true)}
        onHoverEnd={() => handleCircleHover(false)}
      />
    </div>
  );
}

export default Landing;
