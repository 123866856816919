import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { IconMenu2, IconX } from '@tabler/icons-react';

const Header = ({ onProjectsClick, onAboutClick, onExperienceClick, onContactClick }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const navItems = [
    { name: 'Projects', onClick: onProjectsClick },
    { name: 'About', onClick: onAboutClick },
    { name: 'Experience', onClick: onExperienceClick },
    { name: 'Contact', onClick: onContactClick },
  ];

  const containerVariants = {
    hidden: { opacity: 0, y: -50 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        type: 'spring',
        stiffness: 120,
        damping: 20,
        staggerChildren: 0.1,
      },
    },
  };

  const itemVariants = {
    hidden: { opacity: 0, y: -20 },
    visible: { opacity: 1, y: 0 },
  };

  return (
    <motion.header
      className="fixed top-0 left-0 right-0 bg-white md:bg-transparent backdrop-blur-sm px-6 z-50 py-4"
      variants={containerVariants}
      initial="hidden"
      animate="visible"
    >
      <nav className="flex justify-end items-center mx-auto">
        

        <motion.div className="hidden md:flex space-x-6" variants={itemVariants}>
          {navItems.map((item) => (
            <motion.button
              key={item.name}
              onClick={item.onClick}
              className="text-gray-600 hover:text-gray-900 transition-colors duration-200"
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              {item.name}
            </motion.button>
          ))}
        </motion.div>

        <motion.button
          className="md:hidden text-gray-600 hover:text-gray-900"
          onClick={() => setIsMenuOpen(!isMenuOpen)}
          variants={itemVariants}
        >
          {isMenuOpen ? <IconX /> : <IconMenu2 />}
        </motion.button>
      </nav>

      {isMenuOpen && (
        <motion.div
          className="md:hidden absolute top-full left-0 right-0 bg-white shadow-md"
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -20 }}
          transition={{ duration: 0.3 }}
        >
          {navItems.map((item) => (
            <motion.button
              key={item.name}
              onClick={() => {
                item.onClick();
                setIsMenuOpen(false);
              }}
              className="block w-full text-left px-6 py-3 text-gray-600 hover:bg-gray-100 transition-colors duration-200"
              whileHover={{ x: 5 }}
            >
              {item.name}
            </motion.button>
          ))}
        </motion.div>
      )}
    </motion.header>
  );
};

export default Header;