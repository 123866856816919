import React from "react";
import { motion } from "framer-motion";
import AboutSVG from "./AboutSVG";
import Headshot from "../assets/Headshot.jpg";

const InteractiveText = ({ children }) => (
  <motion.span
    className="inline-block cursor-pointer relative"
    whileHover={{ scale: 1.05 }}
  >
    {children}
    <motion.div
      className="absolute bottom-0 left-0 right-0 h-0.5 bg-gray-800"
      initial={{ scaleX: 0 }}
      whileHover={{ scaleX: 1 }}
      transition={{ duration: 0.2 }}
    />
  </motion.span>
);

const About = React.forwardRef((props, ref) => {
  return (
    <div
      className="relative w-full border-b min-h-screen py-16 px-4 sm:px-6 lg:px-8 flex flex-col lg:flex-row items-center justify-center overflow-hidden"
      ref={ref}
    >
      <div className="relative flex flex-col items-center lg:items-start justify-start z-10 w-full lg:w-1/2 max-w-2xl mx-auto lg:mx-0 mb-12 lg:mb-0">
        <motion.h2
          className="text-4xl sm:text-5xl font-bold text-gray-900 mb-8 lg:mb-12 text-center lg:text-left"
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          About Me
        </motion.h2>

        <motion.div
          className="space-y-6 sm:space-y-8 text-base sm:text-lg text-gray-700 leading-relaxed"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.2 }}
        >
          <p>
            I'm <InteractiveText>Cameron Saidock</InteractiveText>, a senior at
            USC majoring in Computer Science and Business Administration. My
            passion lies at the intersection of{" "}
            <InteractiveText>software engineering</InteractiveText> and{" "}
            <InteractiveText>entrepreneurship</InteractiveText>, where I strive
            to create innovative solutions that make a real impact.
          </p>

          <p>
            As a <InteractiveText>software engineer</InteractiveText>, I've
            honed my skills in full-stack development, with a particular focus
            on <InteractiveText>React</InteractiveText>,{" "}
            <InteractiveText>Node.js</InteractiveText>, and{" "}
            <InteractiveText>cloud technologies</InteractiveText>.
          </p>

          <p>
            My <InteractiveText>entrepreneurial journey</InteractiveText> has
            been marked by founding and leading tech startups. I've experienced
            the full lifecycle of product development, from ideation to market
            launch.
          </p>

          <p>
            I'm passionate about{" "}
            <InteractiveText>
              pushing the boundaries of technology
            </InteractiveText>
            . Whether it's developing AI-powered landscape transformation tools
            or creating RFID-based networking platforms, I constantly seek
            innovative ways to merge cutting-edge tech with practical
            applications. This drive for innovation is at the core of my
            approach to both software engineering and entrepreneurship.
          </p>
        </motion.div>
      </div>
      <div className="relative z-10 w-full lg:w-1/2 max-w-2xl mx-auto lg:mx-0 flex justify-center lg:justify-end">
        <div className="w-full max-w-md">
          <AboutSVG headshot={Headshot} />
        </div>
      </div>
    </div>
  );
});

export default About;
