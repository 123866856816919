import React from "react";
import { motion } from "framer-motion";
import {
  IconBrandGithub,
  IconBrandLinkedin,
  IconMail,
} from "@tabler/icons-react";

const pathVariants = {
  hidden: { pathLength: 0, opacity: 0 },
  visible: {
    pathLength: 1,
    opacity: 1,
    transition: {
      pathLength: {
        duration: 5,
        ease: "easeInOut",
        repeat: Infinity,
        repeatType: "reverse",
      },
      opacity: { duration: 1, ease: "easeInOut" },
    },
  },
};

const AnimatedSVG = () => (
  <motion.svg
    width="1512"
    height="235"
    className="w-full absolute bottom-0 h-full mt-8"
    viewBox="0 0 1512 235"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <motion.rect
      variants={pathVariants}
      x="312.764"
      y="447.038"
      width="224.087"
      height="224.087"
      rx="8.5"
      transform="rotate(-121.06 312.764 447.038)"
      stroke="#2D2D2D"
      stroke-width="3"
    />
    <motion.rect
      x="317.801"
      y="429.623"
      width="199.054"
      height="199.054"
      rx="4"
      transform="rotate(-121.06 317.801 429.623)"
      fill="#2D2D2D"
    />
    <motion.rect
      variants={pathVariants}
      x="1341.26"
      y="396.1"
      width="272.108"
      height="272.108"
      rx="8.5"
      transform="rotate(-105 1341.26 396.1)"
      stroke="#2D2D2D"
      stroke-width="3"
    />
    <motion.rect
      x="1353.19"
      y="377.124"
      width="241.147"
      height="241.147"
      rx="4"
      transform="rotate(-105 1353.19 377.124)"
      fill="#2D2D2D"
    />
    <motion.circle
      variants={pathVariants}
      cx="26.515"
      cy="163.515"
      r="162.015"
      stroke="#2D2D2D"
      stroke-width="3"
    />
    <motion.circle
      cx="27.1345"
      cy="162.896"
      r="142.814"
      fill="#2D2D2D"
      stroke="#2D2D2D"
      stroke-width="3"
    />
    <motion.circle
      variants={pathVariants}
      cx="1180.75"
      cy="241.747"
      r="64.2472"
      stroke="#2D2D2D"
      stroke-width="3"
    />
    <motion.circle
      cx="1181"
      cy="241.498"
      r="56.5269"
      fill="#2D2D2D"
      stroke="#2D2D2D"
      stroke-width="3"
    />
  </motion.svg>
);

const Contact = React.forwardRef((props, ref) => {
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        delay: 0.3,
        when: "beforeChildren",
        staggerChildren: 0.2,
      },
    },
  };

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
    },
  };

  return (
    <motion.div
      ref={ref}
      className="relative w-full min-h-[400px] md:min-h-[300px] flex flex-col items-center justify-start pt-16"
      variants={containerVariants}
      initial="hidden"
      animate="visible"
    >
      <motion.h2
        className="text-4xl font-bold text-black mb-8"
        variants={itemVariants}
      >
        Let's Connect
      </motion.h2>

      <motion.div
        className="flex items-center space-x-6"
        variants={itemVariants}
      >
        <a
          href="mailto:saidock@usc.edu"
          className="flex items-center text-gray-700 hover:text-black transition-colors duration-200"
        >
          <IconMail className="w-6 h-6 mr-2" />
          <span>saidock@usc.edu</span>
        </a>
        <div className="h-6 w-px bg-gray-600"></div>
        <motion.a
          href="https://www.linkedin.com/in/cameronsaidock/"
          target="_blank"
          rel="noopener noreferrer"
          className="text-gray-700 hover:text-black transition-colors duration-200"
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.95 }}
        >
          <IconBrandLinkedin className="w-6 h-6" />
        </motion.a>
        <motion.a
          href="https://github.com/CamSaidock"
          target="_blank"
          rel="noopener noreferrer"
          className="text-gray-700 hover:text-black transition-colors duration-200"
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.95 }}
        >
          <IconBrandGithub className="w-6 h-6" />
        </motion.a>
      </motion.div>


        <AnimatedSVG  />
    </motion.div>
  );
});

export default Contact;
