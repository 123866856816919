import React, { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import AIsthetic from "../assets/AIsthetic.png";
import DeepRefactor from "../assets/DeepRefactor.png";
import DEMO from "../assets/DEMO.png";
import HaveNeed from "../assets/HaveNeed.png";
import ThirtyFirst from "../assets/ThirtyFirst.png";
import Touchbase from "../assets/Touchbase.png";
import TroyLabs from "../assets/TroyLabs.png";
import BlueMailScheduler from "../assets/BlueMailScheduler.png";
import StarryStarryBot from "../assets/StarryStarryBot.png";

import {
  IconArrowRight,
  IconChevronDown,
  IconBrandGithub,
  IconExternalLink,
  IconBrandReact,
  IconBrandPython,
  IconBrandJavascript,
  IconDatabase,
  IconCloud,
  IconCode,
  IconWorld,
} from "@tabler/icons-react";

const techIcons = {
  React: IconBrandReact,
  Python: IconBrandPython,
  JavaScript: IconBrandJavascript,
  MongoDB: IconDatabase,
  AWS: IconCloud,
  NodeJS: IconBrandJavascript,
  // Add more mappings as needed
};

const imageVariants = {
  hidden: { pathLength: 0, opacity: 0 },
  visible: {
    pathLength: 1,
    opacity: 1,
    transition: {
      pathLength: {
        duration: 16,
        ease: "easeInOut",
        repeat: Infinity,
        repeatType: "reverse",
      },
      opacity: { duration: 1, ease: "linear" },
    },
  },
};

const pathVariants = {
  hidden: { pathLength: 0, opacity: 0 },
  visible: {
    pathLength: 1,
    opacity: 1,
    transition: {
      pathLength: {
        duration: 5,
        ease: "easeInOut",
        repeat: Infinity,
        repeatType: "reverse",
      },
      opacity: { duration: 1, ease: "easeInOut" },
    },
  },
};

const AnimatedSVG = () => (
  <motion.svg
    initial="hidden"
    animate="visible"
    width="99"
    height="193"
    viewBox="0 0 99 193"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <motion.rect
      initial="hidden"
      animate="visible"
      variants={pathVariants}
      x="-91.9592"
      y="69.4336"
      width="138.289"
      height="138.289"
      rx="8.5"
      transform="rotate(-29.1653 -91.9592 69.4336)"
      stroke="#2D2D2D"
      stroke-width="3"
    />
    <rect
      x="-82.0043"
      y="72.7054"
      width="123.848"
      height="123.848"
      rx="4"
      transform="rotate(-29.1653 -82.0043 72.7054)"
      fill="#2D2D2D"
    />
  </motion.svg>
);

const AnimatedText = ({ children }) => (
  <motion.span
    initial={{ opacity: 0, y: 20 }}
    animate={{ opacity: 1, y: 0 }}
    transition={{ duration: 0.5 }}
  >
    {children}
  </motion.span>
);

const TechBadge = ({ tech }) => {
  const Icon = techIcons[tech] || IconCode;
  return (
    <motion.span
      className="px-3 py-1 bg-gray-200 text-gray-800 rounded-full text-sm flex items-center space-x-1"
      whileHover={{ scale: 1.05, backgroundColor: "#e5e7eb" }}
      whileTap={{ scale: 0.95 }}
    >
      <Icon size={16} />
      <span>{tech}</span>
    </motion.span>
  );
};

const ProjectCard = ({ project, index }) => {
  const [isExpanded, setIsExpanded] = useState(true);
  const [isHovered, setIsHovered] = useState(false);

  const formatDescription = (description) => {
    return description.split(/(\*\*[^*]+\*\*)/g).map((segment, i) => {
      if (/^\*\*(.+)\*\*$/.test(segment)) {
        return (
          <motion.strong
            key={i}
            className="font-semibold text-gray-900"
            whileHover={{ scale: 1.05 }}
          >
            {segment.replace(/^\*\*|\*\*$/g, "")}
          </motion.strong>
        );
      } else {
        return <AnimatedText key={i}>{segment}</AnimatedText>;
      }
    });
  };

  return (
    <motion.div
      className="w-full px-8 sm:px-10 lg:px-12 py-16 border-b border-gray-200 relative"
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5, delay: index * 0.1 }}
    >
      <motion.div
        className="absolute inset-0 bg-gray-50"
        initial={{ opacity: 0 }}
        animate={{ opacity: isHovered ? 1 : 0 }}
        transition={{ duration: 0.3 }}
      />
      <div
        className="relative z-10 mx-auto"
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <div className="mt-4 flex flex-col sm:flex-row justify-between items-start sm:items-center">
          <div>
            <motion.h3
              className="text-4xl sm:text-5xl font-bold text-gray-900 mb-4"
              initial={{ x: -20 }}
              animate={{ x: 0 }}
              transition={{ duration: 0.5 }}
            >
              {project.title}
            </motion.h3>
            <motion.p
              className="text-xl text-gray-600 mb-6"
              initial={{ x: -20, opacity: 0 }}
              animate={{ x: 0, opacity: 1 }}
              transition={{ duration: 0.5, delay: 0.1 }}
            >
              {project.subtitle}
            </motion.p>
          </div>
          {/* <div className="flex flex-col items-center justify-center gap-3">
            <motion.span
              className="text-6xl font-light text-gray-400"
              initial={{ opacity: 0, rotate: -10 }}
              animate={{ opacity: 1, rotate: 0 }}
              transition={{ duration: 0.5, delay: 0.2 }}
            >
              0{index + 1}
            </motion.span>
            <motion.button
              className="text-gray-600 mt-4 sm:mt-0 focus:outline-none"
              onClick={() => setIsExpanded(!isExpanded)}
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.95 }}
            >
              <motion.div
                animate={{ rotate: isExpanded ? 180 : 0 }}
                transition={{ duration: 0.3 }}
              >
                <IconChevronDown size={32} />
              </motion.div>
            </motion.button>
          </div> */}
        </div>

        <AnimatePresence>
          {isExpanded && (
            <motion.div
              initial={{ opacity: 0, height: 0 }}
              animate={{ opacity: 1, height: "auto" }}
              exit={{ opacity: 0, height: 0 }}
              transition={{ duration: 0.5 }}
              className="mt-8"
            >
              <div className="flex flex-col lg:flex-row gap-12">
                <div className="w-full lg:w-2/5">
                  <motion.div
                    className="relative aspect-video rounded-lg overflow-hidden"
                    initial={{ scale: 0.8, opacity: 0 }}
                    animate={{ scale: 1, opacity: 1 }}
                    transition={{ duration: 0.5 }}
                    whileHover={{ scale: 1.05 }}
                  >
                    <img
                      src={project.image}
                      alt={project.title}
                      className="w-full h-full object-cover shadow-lg"
                    />
                  </motion.div>
                  <motion.div
                    className="mt-8"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ delay: 0.6 }}
                  >
                    <h4 className="text-lg font-semibold text-gray-900 mb-4">
                      Technologies Used:
                    </h4>
                    <div className="flex flex-wrap gap-3">
                      {project.technologies.map((tech, i) => (
                        <motion.div
                          key={i}
                          initial={{ opacity: 0, y: 20 }}
                          animate={{ opacity: 1, y: 0 }}
                          transition={{ delay: 0.1 * i }}
                        >
                          <TechBadge tech={tech} />
                        </motion.div>
                      ))}
                    </div>
                  </motion.div>
                </div>
                <div className="w-full lg:w-3/5">
                  <motion.div
                    className="prose prose-lg text-gray-700 leading-relaxed mb-8"
                    initial={{ y: 20, opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    transition={{ duration: 0.5 }}
                  >
                    {formatDescription(project.description)}
                  </motion.div>
                  {project?.features && (
                    <motion.div
                      className="mb-8"
                      initial={{ y: 20, opacity: 0 }}
                      animate={{ y: 0, opacity: 1 }}
                      transition={{ duration: 0.5, delay: 0.2 }}
                    >
                      <h4 className="text-xl font-semibold text-gray-900 mb-4">
                        Key Features:
                      </h4>
                      <ul className="space-y-3">
                        {project?.features.map((feature, index) => (
                          <motion.li
                            key={index}
                            className="flex items-start"
                            initial={{ x: -20, opacity: 0 }}
                            animate={{ x: 0, opacity: 1 }}
                            transition={{ delay: 0.1 * index }}
                          >
                            <IconArrowRight className="text-[#2d2d2d] mt-1 mr-2 flex-shrink-0" />
                            <span>{feature}</span>
                          </motion.li>
                        ))}
                      </ul>
                    </motion.div>
                  )}
                  <motion.div
                    className="flex flex-wrap gap-4"
                    initial={{ y: 20, opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    transition={{ duration: 0.5, delay: 0.3 }}
                  >
                    {project.website && (
                      <motion.a
                        href={project.website}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="inline-flex items-center px-6 py-3 bg-[#000000] text-white rounded-full hover:bg-[#2d2d2d] transition-colors duration-200"
                        whileHover={{ scale: 1.05 }}
                        whileTap={{ scale: 0.95 }}
                      >
                        <IconWorld size={24} className="mr-2" />
                        Visit Website
                      </motion.a>
                    )}
                  </motion.div>
                </div>
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </motion.div>
  );
};

const Projects = React.forwardRef((props, ref) => {
  const projectData = [
    {
      title: "ThirtyFirst",
      subtitle: "Secure Database Middleware Platform",
      description:
        "ThirtyFirst **revolutionizes database security and management**. This innovative middleware platform not only streamlines the setup of secure cloud databases but also **actively monitors for potential breaches**, providing users with swift resolution options. By implementing **granular access control** and processing database commands for MongoDB, MySQL, and PostgreSQL, ThirtyFirst offers a comprehensive solution for modern database needs. The platform's **proactive approach to security** sets a new standard in data protection, making it an essential tool for businesses prioritizing data integrity and compliance.",
      website: "https://thirtyfirst.io/",
      image: ThirtyFirst,
      technologies: [
        "React",
        "Node.js",
        "JavaScript",
        "AWS",
        "MongoDB",
        "MySQL",
        "PostgreSQL",
      ],
      features: [
        "Real-time breach detection and resolution",
        "Multi-database support (MongoDB, MySQL, PostgreSQL)",
        "Granular access control system",
        "Cloud-native architecture on AWS",
        "Intuitive user interface for database management",
      ],
      impact:
        "ThirtyFirst significantly reduces the risk of data breaches and simplifies database management, allowing businesses to focus on innovation rather than infrastructure concerns.",
    },
    {
      title: "Deep Refactor",
      subtitle: "AI-Powered Code Analytics Platform",
      description:
        "Deep Refactor is at the forefront of **AI-driven code analysis**, revolutionizing how developers approach code quality and user experience. By leveraging cutting edge LLMs, Deep Refactor provides **real-time, context-aware suggestions** for code improvements. The platform's architecture, featuring optimized GitHub API queries and a robust job queueing system, ensures **scalability and responsiveness** even under high demand. The **WebSocket-based real-time interaction system**, coupled with an intuitive code editor, creates a seamless environment for developers to visualize and apply AI-generated suggestions, fostering a **continuous improvement cycle** in software development.",
      website: "https://deeprefactor.dev/",
      image: DeepRefactor,
      technologies: [
        "React",
        "Express.js",
        "Node.js",
        "PostgreSQL",
        "WebSockets",
        "LLM",
        "GitHub API",
      ],
      features: [
        "AI-powered code analysis and suggestion engine",
        "Real-time collaborative code editing",
        "Scalable architecture with optimized API usage",
        "Intuitive visualization of code improvements",
        "Continuous learning system for evolving codebases",
      ],
      impact:
        "Deep Refactor is transforming code review processes, significantly reducing technical debt and improving overall software quality across development teams.",
      website: "https://www.deeprefactor.dev",
    },
    {
      title: "HaveNeed",
      subtitle: "Modernized Bartering Platform",
      image: HaveNeed,
      description:
        "HaveNeed is a **thriving bartering platform** with over **10,000 active users**. As the lead developer for their mobile application, I spearheaded the transition from legacy code to a **cutting-edge React Native platform**. This migration not only modernized the user interface but also **significantly improved performance and user engagement**. The project involved **intricate data migration**, seamless integration with a new database architecture, and the development of a robust backend using Node.js. The result is a **fluid, responsive application** that has revitalized the concept of bartering for the digital age.",
      technologies: [
        "React Native",
        "Node.js",
        "JavaScript",
        "SQL",
        "RESTful APIs",
      ],
      features: [
        "Cross-platform mobile application",
        "Real-time bartering system",
        "User-friendly item listing and discovery",
        "Secure in-app messaging",
        "Reputation and review system",
      ],
      impact:
        "The new HaveNeed platform has seen a 200% increase in user engagement and a 150% growth in successful barters since its launch.",
    },
    {
      title: "TouchBase",
      image: Touchbase,
      subtitle: "RFID-Powered Networking Platform",
      website:
        "https://touchbase.troylabs.vc/user/52fdcf6d-57f2-4dfd-b732-620f30cdcbf8",
      description:
        "TouchBase **reimagines professional networking** for the digital age. Developed for TroyLabs DEMO day, this innovative platform leverages **RFID technology** to create seamless, instant connections. With a system of **300 RFID cards**, TouchBase allows users to create digital profiles instantly by tapping a card, complete with headshots, URLs, and startup information. Attendees can then connect by simply **tapping their phones** to these cards, facilitating immediate information exchange and email connections. Built with React and Node.js, and hosted on AWS, TouchBase demonstrates the power of combining **physical and digital interfaces** for networking events.",
      technologies: [
        "React",
        "Node.js",
        "AWS",
        "RFID Technology",
        "RESTful APIs",
      ],
      features: [
        "Instant digital profile creation via RFID",
        "One-tap connection and information exchange",
        "Real-time email notifications for new connections",
        "Scalable architecture supporting high-volume events",
        "Intuitive event management dashboard",
      ],
      impact:
        "TouchBase revolutionized networking at the DEMO day event, facilitating over 1,500 new connections and receiving overwhelmingly positive feedback from attendees and organizers alike.",
    },
    {
      title: "TroyLabs",
      image: TroyLabs,
      subtitle: "Dynamic Web Presence for Student-led Accelerator",
      description:
        "The TroyLabs website serves as a **digital showcase** for USC's premier student-led startup accelerator. The website goes beyond static information delivery, offering an **immersive, interactive experience** that mirrors the innovation fostered by TroyLabs itself. The design emphasizes **fluid animations, intuitive navigation, and responsive layouts**, ensuring that every visitor, whether a potential startup founder or an industry partner, engages deeply with TroyLabs' mission and achievements.",
      website: "https://troylabs.vc/",
      technologies: ["React", "Tailwind CSS"],
      features: [
        "Interactive startup showcase",
        "Dynamic content loading for optimal performance",
        "Accessibility-focused design",
      ],
      impact:
        "The new website has increased application rates for TroyLabs' accelerator program by 75% and significantly boosted engagement from industry partners.",
      website: "https://www.troylabs.vc",
    },
    {
      title: "DEMO",
      image: DEMO,
      subtitle: "High-Traffic Event Platform",
      website: "https://demo.troylabs.vc/",
      description:
        "The DEMO 2024 website serves as the **digital hub** for one of USC's largest annual startup events, attracting over **1,000 unique attendees** and showcasing more than **50 innovative startups**. Built with React, this platform goes beyond mere event information, offering an **interactive experience** that captures the energy and innovation of DEMO. The website features a dynamic startup directory, and a streamlined registration process.",
      technologies: ["React", "Tailwind CSS"],
      website: "https://www.demo2024.com",
    },
    {
      title: "BlueMail Scheduler",
      image: BlueMailScheduler,
      subtitle: "Intelligent Scheduling Solution",
      description:
        "BlueMail Scheduler **redefines the landscape of appointment scheduling**, offering a sophisticated yet user-friendly platform built on a robust tech stack of React, Express, MySQL, and Node.js. This comprehensive solution empowers users with **unparalleled control over their availability**, featuring intuitive interfaces for creating and managing shared scheduling links. The **seamless integration** with Google and Outlook calendars ensures that BlueMail Scheduler fits effortlessly into existing workflows. **Real-time booking notifications** keep all parties informed, dramatically reducing scheduling conflicts and missed appointments.",
      technologies: [
        "React",
        "Express.js",
        "MySQL",
        "Node.js",
        "Google Calendar API",
        "Outlook API",
      ],
      features: [
        "Customizable booking pages",
        "Multi-timezone support",
        "Automated email reminders and follow-ups",
      ],
    },
    {
      title: "AISthetic",
      image: AIsthetic,
      subtitle: "AI-Driven Landscape Transformation",
      description:
        "AISthetic pushes the boundaries of **visual art and augmented reality**, harnessing the power of **generative AI, computer vision, and image segmentation**. Developed for USC Makers, this groundbreaking project transforms landscapes and spaces based on user prompts, opening new frontiers in **interactive storytelling and immersive experiences**. By seamlessly blending advanced AI algorithms with artistic vision, AISthetic creates a bridge between **technology and creativity**.",
      technologies: ["Python", "TensorFlow", "OpenCV", "React", "CUDA"],
      features: [
        "Real-time landscape transformation based on text prompts",
        "Advanced image segmentation for precise modifications",
      ],
    },
    {
      title: "Starry Starry Bot",
      image: StarryStarryBot,
      subtitle: "Interactive Astronomical Guide",
      description:
        "Starry Starry Bot brings the **wonder of the night sky to life**, combining astronomy, laser technology, and satellite data into an **interactive tool**. Developed for USC Makers, this innovative project uses **real-time data from SIMBAD and AstroPy** to identify and locate celestial bodies visible from the user's location. The **custom-built star map interface** not only displays this information but also controls a **precision laser system**, physically pointing out stars and constellations in the night sky.",
      technologies: [
        "Python",
        "React",
        "Raspberry Pi",
        "AstroPy",
        "SIMBAD API",
      ],
      features: [
        "Real-time celestial body tracking and identification",
        "Custom-built, interactive star map interface",
        "Precision laser pointing system with servo motor control",
        "Integration of astronomical databases for accurate data",
      ],
    },
  ];

  return (
    <div ref={ref} className="bg-white min-h-screen">
      <div className="mx-auto py-16">
        <motion.div
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="mb-16 flex flex-row items-center"
        >
          <div className="min-h-48 h-full min-w-30 items-center justify-center">
            <AnimatedSVG />
          </div>
          <div className="w-full flex flex-col px-8 sm:px-10 lg:px-12">
            <div className="flex items-center justify-start gap-4">
              <h2 className="text-4xl sm:text-5xl font-bold text-gray-900 mb-4">
                Projects
              </h2>
            </div>
            <p className="text-xl text-gray-600">
              Explore my recent work and ongoing projects
            </p>
          </div>
        </motion.div>

        {projectData.map((project, index) => (
          <ProjectCard key={index} project={project} index={index} />
        ))}
      </div>
    </div>
  );
});

export default Projects;
