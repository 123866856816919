import React, { useRef } from 'react';
import { motion } from 'framer-motion';
import Landing from './components/Landing';
import Projects from './components/Projects';
import Experience from './components/Experience';
import Contact from './components/Contact';
import Header from './components/Header';
import About from './components/About';

function App() {
  const projectsRef = useRef(null);
  const aboutRef = useRef(null);
  const experienceRef = useRef(null);
  const contactRef = useRef(null);

  const scrollToSection = (ref) => {
    ref.current.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <motion.div
      className="min-h-screen w-screen h-auto overflow-y-auto overflow-x-hidden flex flex-col"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      <Header
        onProjectsClick={() => scrollToSection(projectsRef)}
        onAboutClick={() => scrollToSection(aboutRef)}
        onExperienceClick={() => scrollToSection(experienceRef)}
        onContactClick={() => scrollToSection(contactRef)}
      />
      <Landing onProjectsClick={() => scrollToSection(projectsRef)} />
      <Projects ref={projectsRef} />
      <About ref={aboutRef} />
      <Experience ref={experienceRef} />
      <Contact ref={contactRef} />
    </motion.div>
  );
}

export default App;