import React, { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import {
  IconArrowRight,
  IconChevronDown,
  IconBriefcase,
} from "@tabler/icons-react";
import BlueMail from "../assets/BlueMail.png";
import TroyLabs from "../assets/TroyLabsLogo.png";
import AIM from "../assets/AIM.png";
import Makers from "../assets/Makers.png";
import RHA from "../assets/RHA.png";
import SplitStay from "../assets/SplitStay.png";

const pathVariants = {
  hidden: { pathLength: 0, opacity: 0 },
  visible: {
    pathLength: 1,
    opacity: 1,
    transition: {
      pathLength: {
        duration: 5,
        ease: "easeInOut",
        repeat: Infinity,
        repeatType: "reverse",
      },
      opacity: { duration: 1, ease: "easeInOut" },
    },
  },
};

const isMobile = window.innerWidth < 768;

const AnimatedSVG = () => (
  <motion.svg
    initial="hidden"
    animate="visible"
    width="99"
    height="193"
    viewBox="0 0 99 193"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <motion.rect
      initial="hidden"
      animate="visible"
      variants={pathVariants}
      x="-91.9592"
      y="69.4336"
      width="138.289"
      height="138.289"
      rx="8.5"
      transform="rotate(-29.1653 -91.9592 69.4336)"
      stroke="#2D2D2D"
      stroke-width="3"
    />
    <rect
      x="-82.0043"
      y="72.7054"
      width="123.848"
      height="123.848"
      rx="4"
      transform="rotate(-29.1653 -82.0043 72.7054)"
      fill="#2D2D2D"
    />
  </motion.svg>
);

const formatDescription = (description) => {
  return description.split(/(\*\*[^*]+\*\*)/g).map((segment, i) => {
    if (/^\*\*(.+)\*\*$/.test(segment)) {
      return (
        <motion.strong
          key={i}
          className="font-semibold text-gray-900"
          whileHover={{ scale: 1.05 }}
        >
          {segment.replace(/^\*\*|\*\*$/g, "")}
        </motion.strong>
      );
    } else {
      return <span key={i}>{segment}</span>;
    }
  });
};

const ExperienceAchievement = ({ category, categoryIndex }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <div key={categoryIndex} className="mb-6">
      <h5 className="text-base sm:text-lg font-semibold text-gray-800 mb-3 flex items-center gap-3">
        <motion.button
          className="focus:outline-none"
          onClick={() => setIsExpanded(!isExpanded)}
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.95 }}
        >
          <motion.div
            animate={{ rotate: isExpanded ? 0 : -90 }}
            transition={{ duration: 0.3 }}
          >
            <IconChevronDown size={18} />
          </motion.div>
        </motion.button>
        {category.title}
      </h5>
      <AnimatePresence>
        {isExpanded && (
          <motion.div
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: "auto" }}
            exit={{ opacity: 0, height: 0 }}
            transition={{ duration: 0.5 }}
            className="mt-3"
          >
            <ul className="space-y-2 sm:space-y-3 ml-6">
              {category.items.map((achievement, index) => (
                <motion.li
                  key={index}
                  className="flex items-start"
                  initial={{ x: -20, opacity: 0 }}
                  animate={{ x: 0, opacity: 1 }}
                  transition={{ delay: 0.1 * index }}
                >
                  <IconArrowRight
                    className="text-[#2d2d2d] mt-1 mr-2 flex-shrink-0"
                    size={16}
                  />
                  <span className="text-sm sm:text-base">
                    {formatDescription(achievement)}
                  </span>
                </motion.li>
              ))}
            </ul>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

const ExperienceCard = ({ experience, index }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  return (
    <motion.div
      className="w-full px-4 sm:px-8 py-8 sm:py-12 border-b border-gray-200 relative overflow-hidden"
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5, delay: index * 0.1 }}
    >
      <motion.div
        className="absolute inset-0 bg-gray-50"
        initial={{ opacity: 0 }}
        animate={{ opacity: isHovered ? 1 : 0 }}
        transition={{ duration: 0.3 }}
      />
      <div
        className="relative z-10 mx-auto"
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <div className="flex flex-col sm:flex-row sm:items-center justify-between mb-4 sm:mb-6">
          <div className="flex items-center mb-4 sm:mb-0">
            <motion.img
              src={experience.image}
              alt={experience.company}
              className="w-12 h-12 sm:w-16 sm:h-16 object-contain mr-4 sm:mr-6"
              initial={{ scale: 0.8, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              transition={{ duration: 0.5 }}
            />
            <div>
              <motion.h3
                className="text-2xl sm:text-3xl truncate text-ellipsis max-w-[70vw] md:max-w-[100%] font-bold text-gray-900"
                initial={{ x: -20 }}
                animate={{ x: 0 }}
                transition={{ duration: 0.5 }}
              >
                {experience.company}
              </motion.h3>
              <motion.div
                className="flex flex-wrap gap-2 mt-2"
                initial={{ x: -20, opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                transition={{ duration: 0.5, delay: 0.1 }}
              >
                {experience.position.map((position, index) => (
                  <span
                    key={index}
                    className="text-xs text-center max-w-[70vw] px-3 sm:text-base bg-gray-100 text-gray-700 py-1 rounded-full"
                  >
                    {position}
                  </span>
                ))}
              </motion.div>
            </div>
          </div>
          <motion.button
            className="text-gray-600 focus:outline-none self-end sm:self-auto"
            onClick={() => setIsExpanded(!isExpanded)}
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.95 }}
          >
            <motion.div
              animate={{ rotate: isExpanded ? 180 : 0 }}
              transition={{ duration: 0.3 }}
            >
              <IconChevronDown size={24} />
            </motion.div>
          </motion.button>
        </div>

        <motion.p
          className="text-sm sm:text-base text-gray-700 mb-4"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5, delay: 0.2 }}
        >
          {experience.duration}
        </motion.p>

        <AnimatePresence>
          {isExpanded && (
            <motion.div
              initial={{ opacity: 0, height: 0 }}
              animate={{ opacity: 1, height: "auto" }}
              exit={{ opacity: 0, height: 0 }}
              transition={{ duration: 0.5 }}
              className="mt-4 sm:mt-6"
            >
              <motion.div
                className="prose prose-sm sm:prose-base text-gray-700 p-3 sm:p-4 border-l-2 border-[#e5e7eb] leading-relaxed mb-6 sm:mb-8"
                initial={{ y: 20, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ duration: 0.5 }}
              >
                {formatDescription(experience.description)}
              </motion.div>
              <motion.div
                className="mb-6 sm:mb-8"
                initial={{ y: 20, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ duration: 0.5, delay: 0.2 }}
              >
                <h4 className="text-lg sm:text-xl font-semibold text-gray-900 mb-4">
                  Achievements
                </h4>
                {experience.achievements.map((category, categoryIndex) => (
                  <ExperienceAchievement
                    key={categoryIndex}
                    category={category}
                    categoryIndex={categoryIndex}
                  />
                ))}
              </motion.div>
              {experience.website && (
                <motion.div
                  className="flex flex-wrap gap-4"
                  initial={{ y: 20, opacity: 0 }}
                  animate={{ y: 0, opacity: 1 }}
                  transition={{ duration: 0.5, delay: 0.3 }}
                >
                  <motion.a
                    href={experience.website}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="inline-flex items-center mt-2 sm:mt-4 px-4 sm:px-6 py-2 sm:py-3 bg-[#000000] text-white text-sm sm:text-base rounded-full hover:bg-[#2d2d2d] transition-colors duration-200"
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                  >
                    <IconBriefcase size={20} className="mr-2" />
                    Visit Website
                  </motion.a>
                </motion.div>
              )}
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </motion.div>
  );
};

const Experience = React.forwardRef((props, ref) => {
  const experienceData = [
    {
      company: "BlueMail",
      image: BlueMail,
      position: ["Software Engineer Intern"],
      duration: "May 2023 - Present",
      description:
        "At BlueMail, a popular email platform with over 10 million users across 100 countries, I have worked on various projects to improve cross-platform compatibility and user experience. My contributions have focused on developing solutions to enhance functionality across different operating systems and introduce smart, AI-driven features.",
      achievements: [
        {
          title: "BlueMail App",
          items: [
            "Developed macOS and Windows versions of BlueMail using React Native, ensuring a consistent user experience across platforms",
            "Created web-compatible components, expanding BlueMail's accessibility to browser-based users",
          ],
        },
        {
          title: "BlueMail Scheduler",
          items: [
            "Led the development of BlueMail Scheduler, an appointment management platform, using React, Express, MySQL, and Node.js",
            "Integrated with Google and Outlook calendars for seamless synchronization and improved user productivity",
            "Designed and deployed a real-time notification system for live booking alerts, enhancing user engagement and scheduling efficiency",
          ],
        },
        {
          title: "Email Classifier",
          items: [
            "Implemented Natural Language Processing models using SciKit Learn and SpaCy for intelligent email classification and information extraction",
            "Developed a Python-based backend hosted on a Flask server to process and analyze incoming emails in real-time, improving information accessibility and user productivity",
          ],
        },
      ],
      website: "https://bluemail.me/",
    },
    {
      company: "TroyLabs",
      image: TroyLabs,
      position: ["President", "Director of Engineering", "Software Engineer"],
      duration: "September 2022 - Present",
      description:
        "As a leader at TroyLabs, USC's only student-led startup accelerator, I've contributed to fostering innovation and supporting growth within our entrepreneurial ecosystem. My role involves strategic leadership, technical guidance, and hands-on development to support emerging startups.",
      achievements: [
        {
          title: "Leadership and Growth",
          items: [
            "Developed growth strategies, including marketing campaigns, event planning, and fundraising initiatives, to enhance support for startups in our accelerator program",
            "Organized TroyLabs' annual DEMO Day, showcasing over 50 startups to an audience of over 1000 industry professionals, investors, and students",
          ],
        },
        {
          title: "Technical Guidance",
          items: [
            "Developed the accelerator's website using React and Tailwind CSS, improving user engagement and information accessibility",
            "Provided technical consulting and development guidance to over 8 startups, aiding their technological advancement",
          ],
        },
        {
          title: "Ecosystem Development",
          items: [
            "Established partnerships with tech companies and VC firms, expanding resources and opportunities for our startups",
            "Expanded the IGNITE initiative to provide events and resources, broadening entrepreneurship access to the entire USC student body ",
          ],
        },
      ],
      website: "https://www.troylabs.vc",
    },
    {
      company: "USC Makers",
      image: Makers,
      position: [
        "Project Manager",
        "Associate Director of Technical Resources",
        "Software Engineer",
      ],
      duration: "Jan 2022 - Present",
      description:
        "At USC Makers, I've held multiple roles, contributing to projects that blend cutting-edge technology with practical applications. My work includes leading transformative projects while developing my leadership skills and technical expertise.",
      achievements: [
        {
          title: "Generative AI Projection",
          items: [
            "Led the development of an AI Projection Project using Python, advanced Computer Vision techniques, and Image Generation algorithms to dynamically transform landscapes based on user prompts",
            "Managed a team of 6 engineers, overseeing algorithm development, systems control, and hardware integration",
          ],
        },
        {
          title: "Constellation Detection",
          items: [
            "Developed software for an astronomical device using laser technology to indicate constellations and stars relative to the user's location",
            "Created an intuitive front-end GUI with React.js for easy device control and data management",
            "Integrated data from the SIMBAD astronomical database and used the Astropy package for accurate star and constellation positioning",
          ],
        },
      ],
      website: "https://viterbimakers.usc.edu/",
    },
    {
      company: "SplitStay",
      image: SplitStay,
      position: ["Chief Technology Officer"],
      duration: "February 2023 - September 2023",
      description:
        "As CTO of SplitStay, I led the technical development of our travel booking platform. This role involved balancing technical implementation with strategic business goals to create a scalable, user-centric product.",
      achievements: [
        {
          title: "Platform Development",
          items: [
            "Led the development of a travel booking platform using React.js and Node.js, creating a seamless user experience",
            "Integrated Mapbox API for real-time location services, enhancing platform usability",
            "Implemented secure payment processing with Stripe API, ensuring safe transactions",
          ],
        },
        {
          title: "Software Strategy",
          items: [
            "Developed a technology roadmap aligning the tech stack with business objectives and market trends",
            "Adopted agile methodologies and DevOps practices, improving development efficiency and product quality",
          ],
        },
      ],
    },
    {
      company: isMobile ? "USC RHA" : "USC Residential Housing Association",
      image: RHA,
      position: [isMobile ? "VP of Operations & Development" : "Vice President of Operations & Development"],
      duration: "June 2022 - May 2023",
      description:
        "In this leadership role, I managed financial operations and community engagement within USC's residential housing system. This position allowed me to develop organizational, financial, and interpersonal skills while positively impacting student life.",
      achievements: [
        {
          title: "Financial Management",
          items: [
            "Managed an annual budget of $100,000, implementing tracking systems and ensuring fiscal responsibility",
          ],
        },
        {
          title: "Community Engagement",
          items: [
            "Launched educational initiatives and involvement opportunities, reaching over 5,000 students across 14 dormitories and apartment complexes",
            "Designed professional development programs for members, enhancing leadership skills and career readiness",
          ],
        },
      ],
    },
    {
      company: isMobile ? "AIM" : "Association of Innovative Marketing",
      image: AIM,
      position: ["Marketing Consultant"],
      duration: "February 2022 - April 2023",
      description:
        "As a Marketing Consultant, I worked with clients like Doordash to develop and implement marketing strategies. This role enhanced my analytical skills, understanding of digital marketing trends, and ability to impact brand visibility and engagement.",
      achievements: [
        {
          title: "Marketing Strategy",
          items: [
            "Developed go-to-market strategies for Doordash, optimizing social channels, refining brand identity, and leveraging influencer partnerships",
            "Increased social media engagement for Doordash by 30% through targeted marketing campaigns",
          ],
        },
        {
          title: "Market Research",
          items: [
            "Conducted market research and competitor analysis, providing insights shaping Doordash's social media strategy for Generation-Z",
            "Created data-driven personas and customer journey maps for precise targeting and personalization of marketing efforts",
          ],
        },
      ],
      website: "https://www.uscaimc.org/",
    },
  ];

  return (
    <div ref={ref} className="bg-white min-h-screen">
      <div className="mx-auto py-8 sm:py-16">
      <motion.div
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="mb-16 flex flex-row items-center"
        >
          <div className="min-h-48 h-full min-w-30 items-center justify-center">
            <AnimatedSVG />
          </div>
          <div className="w-full flex flex-col px-8 sm:px-10 lg:px-12">
            <div className="flex items-center justify-start gap-4">
              <h2 className="text-4xl sm:text-5xl font-bold text-gray-900 mb-4">
                Experience
              </h2>
            </div>
            <p className="text-xl text-gray-600">
              My recent experiences and achievements
            </p>
          </div>
        </motion.div>

        {experienceData.map((experience, index) => (
          <ExperienceCard key={index} experience={experience} index={index} />
        ))}
      </div>
    </div>
  );
});

export default Experience;
